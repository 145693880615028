import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { watch, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Field',
  props: {
  label: String,
  modelValue: String,
  readonly: {
    required: false,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const localValue = ref(props.modelValue);

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newValue) => {
  if (newValue !== localValue.value) {
    localValue.value = newValue;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: __props.label }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        modelValue: localValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localValue).value = $event)),
        readonly: props.readonly
      }, null, 8, ["modelValue", "readonly"])
    ]),
    _: 1
  }, 8, ["label"]))
}
}

})