import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "crud-table" }
const _hoisted_2 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "pt-1 caret-wrapper"
}
const _hoisted_5 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_6 = { class: "flex mt-2 mb-4" }
const _hoisted_7 = { class: "dialog-footer" }
const _hoisted_8 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_CrudHeaderFilter = _resolveComponent("CrudHeaderFilter")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.editOnly && !!_ctx.form)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          class: "mb-2",
          onClick: _ctx.handleCreate
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Create new")
          ])),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.list.bulkActions && _ctx.list.bulkActions.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_text, { size: "small" }, {
            default: _withCtx(() => [
              _createTextVNode("Bulk Actions (" + _toDisplayString(_ctx.selection.length) + "): ", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button_group, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.bulkActions, (action) => {
                return (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: action.name,
                  content: action.description,
                  placement: "bottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      onClick: ($event: any) => (_ctx.handleExecuteBulkAction(action.name)),
                      type: "primary",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(action.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["content"]))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_table, {
      data: _ctx.list.items,
      style: {"width":"100%"},
      size: "small",
      onSortChange: _ctx.handleSortChange,
      onSelectionChange: _ctx.handleSelectionChange,
      ref: "table"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          type: "selection",
          width: "24"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (columnVnode) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(columnVnode), {
            key: columnVnode.props.prop
          }, _createSlots({ _: 2 }, [
            (columnVnode.children)
              ? {
                  name: "default",
                  fn: _withCtx((scope) => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(columnVnode.children.default), {
                      row: scope.row
                    }, null, 8, ["row"]))
                  ]),
                  key: "0"
                }
              : undefined,
            (columnVnode.props.filterable)
              ? {
                  name: "header",
                  fn: _withCtx(({ column }) => [
                    _createElementVNode("div", {
                      class: "flex items-center",
                      onClick: ($event) => _ctx.handleSortClick($event, columnVnode.props.prop)
                    }, [
                      (columnVnode.props?.ownSortable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[6] || (_cache[6] = [
                            _createElementVNode("i", { class: "sort-caret ascending" }, null, -1),
                            _createElementVNode("i", { class: "sort-caret descending" }, null, -1)
                          ])))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(column.label), 1)
                    ], 8, _hoisted_3),
                    _createVNode(_component_CrudHeaderFilter, {
                      column: column,
                      type: columnVnode.props.filterable,
                      modelValue: _ctx.filter[column.property],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.filter[column.property]) = $event)
                    }, null, 8, ["column", "type", "modelValue", "onUpdate:modelValue"])
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1024))
        }), 128)),
        _createVNode(_component_el_table_column, {
          label: "Actions",
          width: _ctx.upload ? 200 : 160
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button_group, null, {
              default: _withCtx(() => [
                (_ctx.upload)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      size: "small",
                      onClick: ($event: any) => (_ctx.handleOpenUpload(scope.row))
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Upload")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (!!_ctx.form)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      size: "small",
                      onClick: ($event: any) => (_ctx.handleEdit(scope.row.id))
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Edit")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (!_ctx.editOnly)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 2,
                      size: "small",
                      type: "danger",
                      onClick: ($event: any) => (_ctx.handleDelete(scope.row))
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Delete")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            (_ctx.inlineBulk && _ctx.list.bulkActions && _ctx.list.bulkActions.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_el_dropdown, null, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_el_dropdown_menu, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.bulkActions, (action) => {
                            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              onClick: ($event: any) => (_ctx.handleExecuteSingleAction(scope.row.id, action.name))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(action.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 256))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        size: "small",
                        type: "default"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Actions ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["width"])
      ]),
      _: 1
    }, 8, ["data", "onSortChange", "onSelectionChange"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_el_pagination, {
        small: "",
        background: "",
        layout: "prev, pager, next",
        "page-size": _ctx.pageSize,
        total: _ctx.list?.total,
        "current-page": _ctx.page,
        "onUpdate:currentPage": _ctx.handlePageUpdate,
        class: "me-4"
      }, null, 8, ["page-size", "total", "current-page", "onUpdate:currentPage"]),
      _createVNode(_component_el_select, {
        modelValue: _ctx.pageSize,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageSize) = $event)),
        class: "me-2",
        size: "small"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePageSizes, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item,
              value: item
            }, null, 8, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_text, { size: "small" }, {
        default: _withCtx(() => [
          _createTextVNode("Total: " + _toDisplayString(_ctx.list?.total), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.editModalVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editModalVisible) = $event)),
      title: "Edit",
      width: "80%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeEditModal()))
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Cancel")
            ])),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.handleSave
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" Confirm ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.editLoading)
          ? (_openBlock(), _createBlock(_component_el_progress, {
              key: 0,
              percentage: 100,
              status: "warning",
              indeterminate: true,
              duration: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.editModalVisible && !_ctx.editLoading)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.form), {
              key: 1,
              modelValue: _ctx.formValues,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formValues) = $event))
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.uploadModalVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.uploadModalVisible) = $event)),
      title: "Upload",
      width: "80%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_el_button, { onClick: _ctx.handleCloseUploadModal }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("Finish")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_upload, {
          class: "upload-demo",
          drag: "",
          action: _ctx.uploadActionUrl,
          multiple: "",
          headers: _ctx.uploadHeaders
        }, {
          tip: _withCtx(() => _cache[13] || (_cache[13] = [
            _createElementVNode("div", { class: "el-upload__tip" }, " jpg/png files with a size less than 500kb ", -1)
          ])),
          default: _withCtx(() => [
            _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
              default: _withCtx(() => [
                _createVNode(_component_upload_filled)
              ]),
              _: 1
            }),
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "el-upload__text" }, [
              _createTextVNode(" Drop file here or "),
              _createElementVNode("em", null, "click to upload")
            ], -1))
          ]),
          _: 1
        }, 8, ["action", "headers"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}