import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container mx-auto px-4 py-2" }
const _hoisted_2 = { class: "flex gap-2 mb-2" }
const _hoisted_3 = { class: "w-1/2" }
const _hoisted_4 = { class: "w-1/2" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_Episode = _resolveComponent("Episode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createTextVNode(" Personality: ")),
        _createVNode(_component_v_select, {
          class: "w-full bg-white text-gray-800 border-gray-300 rounded-md shadow-sm",
          modelValue: _ctx.selectedPersonality,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPersonality) = $event)),
          options: _ctx.availablePersonalities,
          label: "full_name",
          reduce: p => p.id
        }, null, 8, ["modelValue", "options", "reduce"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[4] || (_cache[4] = _createTextVNode(" Show: ")),
        _createVNode(_component_v_select, {
          class: "w-full bg-white text-gray-800 border-gray-300 rounded-md shadow-sm",
          modelValue: _ctx.selectedShow,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedShow) = $event)),
          options: _ctx.availableShows,
          label: "name",
          reduce: p => p.id
        }, null, 8, ["modelValue", "options", "reduce"])
      ]),
      (_ctx.selectedPersonality)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, "only guests appearances are displayed"))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.episodes, (episode) => {
      return (_openBlock(), _createBlock(_component_Episode, {
        episode: episode,
        key: episode.id,
        "onClick:personality": _ctx.onPersonalityClick,
        "onClick:show": _ctx.onShowClick
      }, null, 8, ["episode", "onClick:personality", "onClick:show"]))
    }), 128)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleLoadMore()))
      }, "Load More")
    ])
  ]))
}