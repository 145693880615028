import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "mb-4 font-monospace text-blue-600" }
const _hoisted_4 = { key: 0 }

import { useTemplateRef } from "vue"
import CrudTable from "@/components/crud/CrudTable.vue";
import { FEATURE_YOUTUBE_ID } from "../../../episodeFeatures/featureConstants";
import { useRequest } from "@/mixins/useRequest";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminProcessTranscriptsView',
  setup(__props) {

let table = useTemplateRef("table")

let url = "https://" + process.env.VUE_APP_PODCASTR_SITE
// url = ""

let processTranscript = useRequest("GET", (id, reqId) => `/api/transcript-requests/download/${id}/${reqId}`)

function downloadTranscript(row) {
  let feature = row.features.find(el => el.type === FEATURE_YOUTUBE_ID);
  processTranscript(feature.value, row.id).then(res => {
    setTimeout(() => {
      table.value.loadData();
    }, 1000)

  })
}

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "me-2" }, "data loaded from:", -1)),
        _createElementVNode("pre", _hoisted_3, _toDisplayString(_unref(url)), 1)
      ]),
      _createVNode(CrudTable, {
        ref_key: "table",
        ref: table,
        "get-endpoint": _unref(url) + '/api/transcript-requests',
        endpoint: "/api/transcript-requests"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID",
            width: "60",
            sortable: "custom"
          }),
          _createVNode(_component_el_table_column, {
            prop: "show_episode_id",
            label: "Episode id",
            width: "180"
          }),
          _createVNode(_component_el_table_column, { label: "Episode" }, {
            default: _withCtx((scope) => [
              (scope.row.show_episodes)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(scope.row.show_episodes[0].name), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Actions",
            width: "300"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                onClick: ($event: any) => (downloadTranscript(scope.row))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Download and update remote")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["get-endpoint"])
    ])
  ]))
}
}

})