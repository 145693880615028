import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "font-bold text-slate-700" }
const _hoisted_5 = { class: "flex ms-auto" }
const _hoisted_6 = ["onClickCapture"]
const _hoisted_7 = ["onClickCapture"]

import { computed } from "vue"
import { orderBy } from "lodash"


export default /*@__PURE__*/_defineComponent({
  __name: 'Episode',
  props: ['episode'],
  emits: ["click:personality", "click:show"],
  setup(__props, { emit: __emit }) {

let emit = __emit


let orderedPersonalities = computed(() => {
  return orderBy(__props.episode.personalities, "appeared_as", "desc")
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'episode-detail', params: { id: __props.episode.id } },
    class: "episode block hover:no-underline rounded-lg hover:shadow cursor-pointer mb-4 border py-2 px-3 border-slate-400 overflow-hidden text-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", null, [
          (__props.episode.episode_number)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.episode.episode_number) + ". ", 1))
            : _createCommentVNode("", true),
          _createTextVNode(_toDisplayString(__props.episode.name), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            class: "hover:underline",
            onClickCapture: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('click:show', __props.episode.id)), ["prevent"]))
          }, _toDisplayString(__props.episode.show_name), 33)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orderedPersonalities), (p) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "personality",
              key: p.id
            }, [
              (p.appeared_as === 'host')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "hover:underline",
                    onClickCapture: _withModifiers(($event: any) => (_ctx.$emit('click:personality', p.id)), ["prevent"])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(p.full_name), 1)
                  ], 40, _hoisted_6))
                : (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    class: "hover:underline",
                    onClickCapture: _withModifiers(($event: any) => (_ctx.$emit('click:personality', p.id)), ["prevent"])
                  }, _toDisplayString(p.full_name), 41, _hoisted_7))
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})