<template>
  <div v-if="feature" class="mb-4">
    <h4 v-if="label" class="mb-2 text-xl font-bold">{{ label }}</h4>
    <p v-if="!parsedFeature">{{ feature }}</p>
    <div v-else>
      <ul class="list-disc">
        <li class="mb-1" v-for="f in parsedFeature">{{ f }}</li>
      </ul>
    </div>
  </div>
</template>
<script setup>
let { features, type, label } = defineProps(["features", "type", "label"])


let feature = features.find(f => f.type === type)

if (feature) {
  feature = feature.value
}

let parsedFeature;

try {
  parsedFeature = JSON.parse(feature);
} catch (e) {
  console.log("failed to parse feature type", feature);

  try {
    parsedFeature = JSON.parse(feature.replaceAll("{", "[").replaceAll("}", "]"))
  } catch (e) {}
}

</script>
