<template>
  <div class="container mx-auto px-4 py-2">
    <div class="mb-2" v-admin-only>
      <router-link class="hover:underline text-blue-600" :to="{ name: 'admin.episodes', query: { id: episode.id }}">show in admin</router-link>
    </div>

    <div class="flex">
      <div class="me-4 flex-shrink-0">
        <img v-if="episode.show.image_uuid" :src="baseUrl + '/' + episode.show.image_uuid" class="w-36 h-36 object-cover rounded-md border-4 border-solid border-black">
        <div v-else>
          <MicrophoneIcon class="text-white rounded-lg bg-blue-600 h-36 w-36 mt-2 mb-2 p-2" />
        </div>
      </div>
      <div>
        <h1 class="text-3xl font-extralight text-gray-600">{{episode.name}}</h1>
        <h2 class="text-lg text-black"><episode-feature :features="episode.features" type="feature:ai:subtitle"/></h2>
        <div v-if="episode.links" class="flex gap-2 text-blue-600 font-sm mt-2">
          <episode-links :episode="episode" />
        </div>
      </div>
    </div>
    <div class="flex mt-4 gap-4 flex-wrap">
      <episode-personalities :personalities="episode.personalities" hosts />
      <episode-personalities :personalities="episode.personalities" guests />
    </div>
    <div class="mt-4">
      <episode-feature :features="episode.features" type="feature:ai:summary" label="Summary" />
      <episode-feature :features="episode.features" type="feature:ai:highlights" label="Highlights" />
      <episode-feature :features="episode.features" type="feature:ai:takeaways" label="Takeaways" />
    </div>

  </div>
</template>

<script setup>
import { useRequest } from "@/mixins/useRequest";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { MicrophoneIcon } from '@heroicons/vue/24/solid'
import EpisodeFeature from "@/components/frontend/EpisodeFeature.vue";
import EpisodeLinks from "@/components/frontend/EpisodeLinks.vue";
import EpisodePersonalities from "@/components/frontend/EpisodePersonalities.vue";


let baseUrl = process.env.VUE_APP_CF_WORKER_URL;
let episode = ref(null);
let route = useRoute();
let getEpisode = useRequest("GET", `/api/public/episodes/${route.params.id}`);

getEpisode().then((ep) => {
  episode.value = ep;
})
</script>
