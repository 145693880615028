<template>
  <div class="flex h-18 w-[400px] pe-4 ps-2 gap-4">
    <div class="flex-shrink-0">
      <UserIcon class="text-white rounded-full bg-amber-500 h-16 w-16 mt-2 mb-2 p-2" />
    </div>
    <div class="pt-2">
      <div class="mb-1" :class="isHost ? 'font-bold' : ''">{{ personality.full_name }} <span class="text-gray-700 text-sm font-extralight ps-2" v-if="isHost">Host</span></div>
      <div class="text-sm text-gray-500">{{ personality.description }}</div>
    </div>
  </div>
</template>
<script setup>
let { personality } = defineProps(["personality"])
import { UserIcon } from '@heroicons/vue/24/solid'

let isHost = personality.appeared_as === "host"
let isGuest = personality.appeared_as === "guest"
</script>
