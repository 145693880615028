import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-800" }
const _hoisted_2 = { class: "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "flex items-center justify-between h-16" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "ml-10 flex items-baseline space-x-4" }

import { ref } from 'vue'

// state

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const showAdmin = ref(false)

if (localStorage.getItem("podcastr-session-id")) {
  showAdmin.value = true;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_admin_only = _resolveDirective("admin-only")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-shrink-0" }, [
              _createElementVNode("span", { class: "text-white font-bold text-xl" }, "podcastr.me")
            ], -1)),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  class: "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium",
                  to: "/"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("episodes")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  class: "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium",
                  to: "/my-episodes"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("my episodes")
                  ])),
                  _: 1
                }),
                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                  class: "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium",
                  to: "/custom-episodes"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("ultra secret")
                  ])),
                  _: 1
                })), [
                  [_directive_admin_only]
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                  class: "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium",
                  to: "/admin/episodes"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("admin")
                  ])),
                  _: 1
                })), [
                  [_directive_admin_only]
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ]))
}
}

})