<template>
  <div class="flex">
    <el-select v-model="featureName" filterable placeholder="Feature name" class="w-full">
      <el-option
        v-for="feature in availableFeatures"
        :key="feature"
        :label="feature"
        :value="feature"
      />
    </el-select>

    <el-input placeholder="Feature value" v-model="featureValue"></el-input>
    <el-button @click="emit('remove', feature.id)">remove</el-button>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"

let emit = defineEmits(["remove", "update"])
let { availableFeatures, feature } = defineProps(["availableFeatures", "feature"])

let featureName = ref(feature.type || "");
let featureValue = ref(feature.value || "")

watch([featureName, featureValue], () => {
  emit("update", {
    ...feature,
    type: featureName.value,
    value: featureValue.value,
  })
})
</script>
