import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block" }
const _hoisted_2 = { key: 0 }

import { ref, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'JsonField',
  props: {
  label: {
    required: true,
    type: String
  },
  modelValue: {
    type: Array,
    required: true
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {


const props = __props;

const emit = __emit;

const items = ref([]);

onMounted(() => {
  console.log(Array.isArray(items.value), items.value);

  if (Array.isArray(props.modelValue)) {
    items.value = (props.modelValue || []).slice(0);
  }
  else {
    items.value = []
  }
});

const update = () => emit("update:modelValue", items.value);

const updateItem = (index, value) => {
  items.value[index] = value;
  update()
};

const removeItem = (index) => {
  items.value.splice(index, 1);
  update()
};

const addItem = () => {
  items.value.push("");
  update()
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { label: __props.label }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (Array.isArray(items.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "flex mb-1"
                }, [
                  _createVNode(_component_el_input, {
                    modelValue: items.value[index],
                    "onUpdate:modelValue": ($event: any) => ((items.value[index]) = $event),
                    onInput: ($event: any) => (updateItem(index, $event.target.value))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"]),
                  _createVNode(_component_el_button, {
                    class: "ms-1",
                    onClick: ($event: any) => (removeItem(index))
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Remove")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_button, { onClick: addItem }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Add Item")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["label"]))
}
}

})