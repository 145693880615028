<template>
  <div class="mt-5">
    <div>
      <div class="flex">
        <div class="me-2">data loaded from:</div>
        <pre class="mb-4 font-monospace text-blue-600">{{ url }}</pre>
      </div>

      <crud-table ref="table" :get-endpoint="url + '/api/transcript-requests'" endpoint="/api/transcript-requests">
        <el-table-column prop="id" label="ID" width="60" sortable="custom" />
        <el-table-column prop="show_episode_id" label="Episode id" width="180" />
        <el-table-column label="Episode">
          <template #default="scope">
            <div v-if="scope.row.show_episodes">
              {{ scope.row.show_episodes[0].name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="300">
          <template #default="scope">
            <el-button @click="downloadTranscript(scope.row)">Download and update remote</el-button>
          </template>
        </el-table-column>
      </crud-table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useTemplateRef } from "vue"
import CrudTable from "@/components/crud/CrudTable.vue";
import { FEATURE_YOUTUBE_ID } from "../../../episodeFeatures/featureConstants";
import { useRequest } from "@/mixins/useRequest";

let table = useTemplateRef("table")

let url = "https://" + process.env.VUE_APP_PODCASTR_SITE
// url = ""

let processTranscript = useRequest("GET", (id, reqId) => `/api/transcript-requests/download/${id}/${reqId}`)

function downloadTranscript(row) {
  let feature = row.features.find(el => el.type === FEATURE_YOUTUBE_ID);
  processTranscript(feature.value, row.id).then(res => {
    setTimeout(() => {
      table.value.loadData();
    }, 1000)

  })
}
</script>
