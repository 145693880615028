import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "line-through" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "font-monospace" }
const _hoisted_9 = { class: "col-6" }

import { ref, onMounted } from 'vue'
import ImportForm from '@/components/forms/ImportForm.vue';
import { useRequest } from "@/mixins/useRequest";
import { formatDate} from "@/services/datetime";
import { ElMessage } from "element-plus";
import { eat } from "@/services/eat";
import CrudTable from "@/components/crud/CrudTable.vue";
import ActionButton from "@/components/ActionButton.vue";
import dayjs from "dayjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminImportView',
  setup(__props) {

const shows = ref([])
const getShows = useRequest("GET", "/api/shows/all")

onMounted(() => {
  getShows().then(res => {
    shows.value = res;
  })
})

let activeName = ref("new")
let analyzedData = ref({})
let dialogAnalyzeVisible = ref(false)
let analyzeImport = useRequest("GET", (id: string) => `/api/imports/${id}/analyze`)

function analyze(id: string) {
  analyzedData.value = {};
  dialogAnalyzeVisible.value = true;
  analyzeImport(id).then(res => {
    analyzedData.value = res;
  })
}

function idToShow(show_id: string) {
  return shows.value.find(s => s.id === show_id)?.name
}



return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(CrudTable, {
        endpoint: "/api/imports",
        form: ImportForm,
        upload: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "file_name",
            label: "Name",
            width: "250"
          }),
          _createVNode(_component_el_table_column, {
            prop: "datetime",
            label: "Date",
            width: "180"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, _toDisplayString(_unref(formatDate)(scope.row.datetime)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "show_id",
            label: "Show",
            width: "180"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, _toDisplayString(idToShow(scope.row.show_id)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { label: "Import Actions" }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_button, {
                  class: "me-4",
                  type: "info",
                  size: "small",
                  onClick: ($event: any) => (analyze(scope.row.id))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Analyze")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _cache[6] || (_cache[6] = _createTextVNode(" Import: ")),
                _createVNode(_component_el_button_group, { class: "ms-1" }, {
                  default: _withCtx(() => [
                    _createVNode(ActionButton, {
                      type: "primary",
                      size: "small",
                      getAction: `/api/imports/${scope.row.id}/create-episodes`
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Episodes")
                      ])),
                      _: 2
                    }, 1032, ["getAction"]),
                    _createVNode(ActionButton, {
                      type: "primary",
                      size: "small",
                      getAction: `/api/imports/${scope.row.id}/create-personalities`
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Guests")
                      ])),
                      _: 2
                    }, 1032, ["getAction"]),
                    _createVNode(ActionButton, {
                      type: "primary",
                      size: "small",
                      getAction: `/api/imports/${scope.row.id}/create-appearances`
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Appearances")
                      ])),
                      _: 2
                    }, 1032, ["getAction"])
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogAnalyzeVisible),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(dialogAnalyzeVisible) ? (dialogAnalyzeVisible).value = $event : dialogAnalyzeVisible = $event)),
        title: "Analyze results",
        width: "80%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            modelValue: _unref(activeName),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(activeName) ? (activeName).value = $event : activeName = $event)),
            class: "demo-tabs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: "New episodes",
                name: "new"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table, {
                    data: _unref(analyzedData).uniqueEpisodes
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        property: "episode",
                        label: "Episode",
                        width: "150"
                      }, {
                        default: _withCtx((scope) => [
                          (scope.row.db_id)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("span", _hoisted_4, _toDisplayString(scope.row.episode), 1),
                                _cache[7] || (_cache[7] = _createTextVNode()),
                                _createElementVNode("small", null, "found by " + _toDisplayString(scope.row.db_found_by), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(scope.row.episode), 1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        property: "date",
                        label: "Date",
                        width: "150"
                      }),
                      _createVNode(_component_el_table_column, {
                        property: "title",
                        label: "Title",
                        width: "200"
                      }),
                      _createVNode(_component_el_table_column, {
                        property: "personalities",
                        label: "New Personalities"
                      }, {
                        default: _withCtx((scope) => [
                          _createTextVNode(_toDisplayString(_unref(analyzedData).newPersonalitiesMap[scope.row.guid]) + " ", 1),
                          _createVNode(_component_el_text, {
                            class: "line-through",
                            type: "info"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(analyzedData).existingPersonalitiesMap[scope.row.guid]), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, {
                label: "Discarded episodes",
                name: "discarded"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table, {
                    data: _unref(analyzedData).discardedEpisodes
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        property: "episode",
                        label: "Episode",
                        width: "150"
                      }),
                      _createVNode(_component_el_table_column, {
                        property: "date",
                        label: "Date",
                        width: "150"
                      }),
                      _createVNode(_component_el_table_column, {
                        property: "title",
                        label: "Title",
                        width: "200"
                      })
                    ]),
                    _: 1
                  }, 8, ["data"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, {
                label: "New Personalities",
                name: "personalities"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[8] || (_cache[8] = _createElementVNode("h4", null, "Mapped", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(analyzedData).newPersonalitiesMap, (personalities, guid) => {
                        return (_openBlock(), _createElementBlock("div", { key: _ctx.episode }, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(guid), 1),
                          _createTextVNode(": " + _toDisplayString(personalities), 1)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[9] || (_cache[9] = _createElementVNode("h4", null, "All new personalities", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(analyzedData).newPersonalities, (personality) => {
                        return (_openBlock(), _createElementBlock("div", { key: personality }, _toDisplayString(personality), 1))
                      }), 128))
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, {
                label: "Appearances",
                name: "appearances"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_alert, {
                    title: "Appearances are available after successful import of the episodes",
                    type: "info"
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(analyzedData).appearancesMap, (personalities, episode) => {
                    return (_openBlock(), _createElementBlock("div", { key: episode }, _toDisplayString(episode) + ": " + _toDisplayString(personalities), 1))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}
}

})