import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "extra-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_crud_table = _resolveComponent("crud-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_crud_table, {
        endpoint: "/api/episodes",
        form: _ctx.Form,
        "inline-bulk": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID",
            width: "60"
          }),
          _createVNode(_component_el_table_column, {
            prop: "episode_number",
            label: "No",
            width: "60"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Name",
            width: "180"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_router_link, {
                class: "hover:underline text-blue-600",
                to: { name: 'episode-detail', params: { id: scope.row.id || 0 } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "description",
            label: "Description",
            width: "180"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", {
                class: "truncate",
                title: scope.row.description
              }, _toDisplayString(scope.row.description), 9, _hoisted_2),
              _createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.guid), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "datetime",
            label: "Air Date",
            width: "120"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_ctx.formatDate(scope.row.datetime)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "show_id",
            label: "Show",
            width: "180"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.show_name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "personality_id",
            label: "Personalities",
            width: "180",
            filterable: "personality",
            sortable: false
          }, {
            default: _withCtx((scope) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.personalities, (personality) => {
                return (_openBlock(), _createElementBlock("div", null, [
                  _createVNode(_component_el_text, { size: "small" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(personality.full_name) + " ", 1),
                      (personality.appeared_as === 'host')
                        ? (_openBlock(), _createBlock(_component_el_tag, { key: 0 }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Host")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 256))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Features",
            width: "200",
            sortable: false
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, [
                (scope.row.hasTranscriptRequest)
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 0,
                      type: "warning",
                      class: "mb-1 me-1"
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("remote-transcript-requested")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.features, (feature) => {
                  return (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: feature.id,
                    content: feature.value
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tag, {
                        class: "mb-1 me-1",
                        onClick: ($event: any) => (_ctx.saveToClipboard(feature.value))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(feature.type), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["form"])
    ])
  ]))
}