export const FEATURE_DEBUG = "feature:debug"
export const FEATURE_YOUTUBE_ID = "feature:youtube:id"
export const FEATURE_YOUTUBE_TITLE = "feature:youtube:title"
export const FEATURE_TRANSCRIPT = "feature:transcript"
export const FEATURE_TRANSCRIPT_TOKEN_COUNT_OPEN_AI = "feature:ai:tokens:openai"
export const FEATURE_TRANSCRIPT_TOKEN_COUNT_GOOGLE = "feature:ai:tokens:google"
export const FEATURE_TRANSCRIPT_AI_SUMMARY = "feature:ai:summary"
export const FEATURE_TRANSCRIPT_AI_TAGS = "feature:ai:tags"
export const FEATURE_TRANSCRIPT_AI_CATEGORY = "feature:ai:category"
export const FEATURE_TRANSCRIPT_AI_TOPICS = "feature:ai:topics"
export const FEATURE_TRANSCRIPT_AI_SUBTITLE = "feature:ai:subtitle"
export const FEATURE_TRANSCRIPT_AI_HIGHLIGHTS = "feature:ai:highlights"
export const FEATURE_TRANSCRIPT_AI_REFERENCES = "feature:ai:references"
export const FEATURE_TRANSCRIPT_AI_MOOD = "feature:ai:mood"
export const FEATURE_TRANSCRIPT_AI_TAKEAWAYS = "feature:ai:takeaways"

export const AI_FEATURES = [
  FEATURE_TRANSCRIPT_AI_SUMMARY,
  // FEATURE_TRANSCRIPT_AI_TAGS,
  FEATURE_TRANSCRIPT_AI_CATEGORY,
  FEATURE_TRANSCRIPT_AI_TOPICS,
  FEATURE_TRANSCRIPT_AI_SUBTITLE,
  FEATURE_TRANSCRIPT_AI_HIGHLIGHTS,
  FEATURE_TRANSCRIPT_AI_REFERENCES,
  // FEATURE_TRANSCRIPT_AI_MOOD,
  FEATURE_TRANSCRIPT_AI_TAKEAWAYS
]

export const YOUTUBE_FEATURES = [
  FEATURE_YOUTUBE_ID,
  FEATURE_YOUTUBE_TITLE
]

export const FEATURES = [
  ...YOUTUBE_FEATURES,

  FEATURE_TRANSCRIPT,
  FEATURE_TRANSCRIPT_TOKEN_COUNT_OPEN_AI,
  FEATURE_TRANSCRIPT_TOKEN_COUNT_GOOGLE,

  ...AI_FEATURES,

  FEATURE_DEBUG
];


