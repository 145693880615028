<template>
  <episode-personality v-for="p in filtered" :personality="p"></episode-personality>
</template>
<script setup>
import EpisodePersonality from "@/components/frontend/EpisodePersonality.vue";

let { personalities, hosts, guests } = defineProps({
  personalities: Array,
  guests: {
    type: Boolean,
    default: false,
    required: false
  },
  hosts: {
    type: Boolean,
    default: false,
    required: false
  }
})

let filtered = []

if (hosts) {
  filtered.push(...personalities.filter(p => p.appeared_as === "host"))
}
if (guests) {
  filtered.push(...personalities.filter(p => p.appeared_as === "guest"))
}
</script>
