import axios from "axios"
import { sessionHeaderName } from "common/constants"

let isProd = process.env.NODE_ENV === "production";
export let baseUrl = isProd ? "" : "http://localhost:3000"
export let headers = { [sessionHeaderName]: localStorage.getItem("podcastr-session-id")}

export function request(method: string, url: string, body?: any) {
  let fullUrl = baseUrl + url;

  if (url.startsWith("http")) {
    fullUrl = url;
  }

  return axios({
    data: body,
    method,
    url: fullUrl,
    headers,
  }).then(({ data }) => data)
}
