import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "d-block" }
const _hoisted_5 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_JsonField = _resolveComponent("JsonField")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_GuestParserOptions = _resolveComponent("GuestParserOptions")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-width": "200px" }, {
    default: _withCtx(() => [
      _createVNode(_component_Field, {
        label: "Name",
        modelValue: _ctx.form.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_Field, {
        label: "Source",
        modelValue: _ctx.form.source,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.source) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_Field, {
        label: "Youtube Channel Id",
        modelValue: _ctx.form.youtube_channel_id,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.youtube_channel_id) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_JsonField, {
        label: "Links",
        modelValue: _ctx.form.links,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.links) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_form_item, { label: "Transformer" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            class: "w-100",
            modelValue: _ctx.form.transformer,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.transformer) = $event)),
            options: _ctx.availableTransformers,
            label: "Transformer"
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Transformer plugin" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            class: "w-100",
            modelValue: _ctx.form.transformer_plugin,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.transformer_plugin) = $event)),
            options: _ctx.availableTransformerPlugins,
            label: "Transformer plugin"
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Guests Parser" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            class: "w-100",
            modelValue: _ctx.form.guest_parser,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.guest_parser) = $event)),
            options: _ctx.availableGuestParsers,
            label: "name",
            reduce: (row) => row.name
          }, null, 8, ["modelValue", "options", "reduce"]),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.selectedParserParams)
              ? (_openBlock(), _createBlock(_component_GuestParserOptions, {
                  key: 0,
                  params: _ctx.selectedParserParams,
                  modelValue: _ctx.parserParamsValues,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.parserParamsValues) = $event))
                }, null, 8, ["params", "modelValue"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Guests Parser Params" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            disabled: "",
            modelValue: _ctx.parserParamsValuesJson,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.parserParamsValuesJson) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Image" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            disabled: "",
            modelValue: _ctx.form.image_uuid,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.image_uuid) = $event))
          }, null, 8, ["modelValue"]),
          (_ctx.form.image_uuid)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "previewImg me-2",
                src: _ctx.baseImageUrl + '/' + _ctx.form.image_uuid
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_el_upload, {
            class: "upload-demo",
            ref: "uploadRef",
            "file-list": _ctx.fileList,
            "onUpdate:fileList": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.fileList) = $event)),
            "on-success": _ctx.handleSuccess,
            "on-change": _ctx.handleChange,
            headers: _ctx.uploadHeaders,
            drag: "",
            action: _ctx.uploadUrl,
            method: "POST",
            "auto-upload": false
          }, {
            tip: _withCtx(() => _cache[14] || (_cache[14] = [
              _createElementVNode("div", { class: "el-upload__tip" }, " jpg/png files with a size less than 500kb ", -1)
            ])),
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
                default: _withCtx(() => [
                  _createVNode(_component_upload_filled)
                ]),
                _: 1
              }),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "el-upload__text" }, [
                _createTextVNode(" Drop file here or "),
                _createElementVNode("em", null, "click to upload")
              ], -1))
            ]),
            _: 1
          }, 8, ["file-list", "on-success", "on-change", "headers", "action"])
        ]),
        _: 1
      }),
      (_ctx.form.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_form_item, { label: "Regularly hosted by" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regularHosts, (personality) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: personality.personality_id
                    }, [
                      _createTextVNode(_toDisplayString(personality.full_name) + " ", 1),
                      _createVNode(_component_el_button, {
                        size: "small",
                        type: "danger",
                        onClick: ($event: any) => (_ctx.removeRegularHost(personality.personality_id))
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode("x")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.selectedHost,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedHost) = $event)),
                      filterable: "",
                      placeholder: "Host"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePersonalities, (person) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: person.id,
                            label: person.full_name,
                            value: person.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.fromDate,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.fromDate) = $event)),
                      type: "date",
                      placeholder: "From",
                      size: "small"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.toDate,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.toDate) = $event)),
                      type: "date",
                      placeholder: "To",
                      size: "small"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_el_button, {
                      class: "ms-1",
                      onClick: _ctx.addRegularHost
                    }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode("Add Regular Host")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}