import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_JsonField = _resolveComponent("JsonField")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-width": "200px" }, {
    default: _withCtx(() => [
      _createVNode(_component_Field, {
        label: "Full name",
        modelValue: _ctx.form.full_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.full_name) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_form_item, { label: "Description" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.form.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
            type: "textarea",
            rows: "4"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_JsonField, {
        label: "Alternative names",
        modelValue: _ctx.form.alternative_names,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.alternative_names) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_JsonField, {
        label: "Aliases",
        modelValue: _ctx.form.aliases,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.aliases) = $event))
      }, null, 8, ["modelValue"]),
      _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
      (_ctx.form.id !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "me-1 align-self-center" }, "Merge into:", -1)),
              _createVNode(_component_v_select, {
                class: "w-100 me-1",
                modelValue: _ctx.selectedPersonality,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedPersonality) = $event)),
                options: _ctx.personalities,
                label: "full_name",
                reduce: p => p.id
              }, null, 8, ["modelValue", "options", "reduce"]),
              _createVNode(_component_el_button, {
                type: "warning",
                onClick: _ctx.mergeWith
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Merge")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.appearances.length > 0 && _ctx.form.id !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
            _cache[8] || (_cache[8] = _createElementVNode("div", null, "Appearances", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appearances, (appearance) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "d-inline me-2",
                key: appearance.id
              }, [
                _createElementVNode("small", null, "episodeId " + _toDisplayString(appearance.show_episode_id) + " as " + _toDisplayString(appearance.appeared_as), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}