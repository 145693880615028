import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "mt-1" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "mt-1" }
const _hoisted_5 = { class: "dialog-footer" }
const _hoisted_6 = { class: "dialog-footer" }
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_crud_table = _resolveComponent("crud-table")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ScrapeJsonView = _resolveComponent("ScrapeJsonView")!
  const _component_JsonViewer = _resolveComponent("JsonViewer")!
  const _component_PluginEditor = _resolveComponent("PluginEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_crud_table, {
        ref: "table",
        endpoint: "/api/scrapes",
        form: _ctx.Form
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID",
            width: "40"
          }),
          _createVNode(_component_el_table_column, {
            label: "Show",
            width: "150"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_ctx.idToShow(scope.row.show_id)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Engines",
            width: "300"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_text, { size: "small" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(scope.row.source), 1),
                  _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(scope.row.guest_parser), 1),
                  _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(scope.row.transformer), 1),
                  _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(scope.row.transformer_plugin), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Results",
            width: "150"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, [
                (scope.row.result)
                  ? (_openBlock(), _createBlock(_component_el_tag, { key: 0 }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("JSON")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_2, [
                (scope.row.csv_result)
                  ? (_openBlock(), _createBlock(_component_el_tag, { key: 0 }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("CSV")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { label: "Scraping" }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_el_button_group, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_popconfirm, {
                      title: "Confirm?",
                      onConfirm: ($event: any) => (_ctx.scrape(scope.row.id))
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "info",
                          size: "small"
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("Scrape")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"]),
                    (scope.row.result)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "info",
                          size: "small",
                          onClick: ($event: any) => (_ctx.openJsonModal(scope.row))
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Show JSON ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (scope.row.result)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 1,
                          type: "info",
                          size: "small",
                          onClick: ($event: any) => (_ctx.saveCsv(scope.row.id))
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Create CSV ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (scope.row.result && scope.row.csv_result)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 2,
                          type: "info",
                          size: "small",
                          onClick: ($event: any) => (_ctx.download(scope.row))
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode("Download CSV ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_button, {
                      type: "info",
                      size: "small",
                      onClick: ($event: any) => (_ctx.openImportModal(scope.row))
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode("Create Import ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "info",
                      size: "small",
                      onClick: ($event: any) => (_ctx.saveRemoved(scope.row))
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("Save Removed GUIDs ")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_button, {
                  onClick: ($event: any) => (_ctx.openPluginEditor(scope.row)),
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Edit plugin")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["form"])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.importModalVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.importModalVisible) = $event)),
      title: "Create new import",
      width: "80%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, { onClick: _ctx.importModalSave }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode("Save")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.importModalVisible = false))
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode("Close")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.importName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.importName) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.jsonModalVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.jsonModalVisible) = $event)),
      title: "JSON View",
      width: "80%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_el_button, { onClick: _ctx.jsonModalSave }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createTextVNode("Save")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.jsonModalVisible = false))
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("Close")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ScrapeJsonView, {
          result: _ctx.selectedResults,
          plugin: _ctx.selectedPlugin,
          "scrape-id": _ctx.selectedScrape.id
        }, null, 8, ["result", "plugin", "scrape-id"]),
        (_ctx.jsonModalVisible)
          ? (_openBlock(), _createBlock(_component_JsonViewer, {
              key: 0,
              value: _ctx.selectedResults
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.pluginEditorVisible,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pluginEditorVisible) = $event)),
      title: "Plugin Editor",
      width: "90%"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, { onClick: _ctx.jsonModalSave }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createTextVNode("Save")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.pluginEditorVisible = false))
          }, {
            default: _withCtx(() => _cache[24] || (_cache[24] = [
              _createTextVNode("Close")
            ])),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_PluginEditor, {
          result: _ctx.selectedResults,
          "scrape-id": _ctx.selectedScrapeId,
          "show-id": _ctx.selectedScrape.show_id
        }, null, 8, ["result", "scrape-id", "show-id"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}